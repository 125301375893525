(function ($) {

  // ---------- APP START
  var App = {
    config: {
      scrollOffset: 100
    },

    init: function () {
      // Init SVG polyfill
      if (typeof (svg4everybody) !== 'undefined') svg4everybody();

      // Init object-fill polyfill
      if (typeof (objectFitImages) !== 'undefined') objectFitImages();

      // Remove focus state when clicking on a button with a mouse
      $(':not(form) button, a').on('click', function (e) {
        if (e.clientX && e.clientY)
          $(this).trigger('blur');
      });
      $('a[href^="#"]:not([href="#rgpd"]):not(.js-more-offers)').on('click', function (e) {
        e.preventDefault();

        var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href');

        if ($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true)
          App.scroll(scrollTo, $(this).data('scroll-offset'));
      });

      if ($('[data-scroll]').length) {
        App.scroll($('[data-scroll]').data('scroll'));
      }

      if ($('.header').length) {
        App.menuBurger();
      }
      $('select').select2({
        minimumResultsForSearch: Infinity
      });
      $('.file-upload-button').on('click', function(){
        $(this).next('input').trigger('click');
      });
      $('input[type="file"]').on('change', function(){
        var filename = $(this)[0].files[0].name;
        $(this).prev('.file-upload-button').find('span').text(filename);
        $(this).prev('.file-upload-button').find('span').css('opacity', 1);
      });
      if ($('.jobs-list').length){
        $('.js-more-offers').on('click', function(e){
          e.preventDefault();
          $('.jobs-list__item').css('display', 'block');
          $('.js-more-offers').css('display', 'none');
        });
      }
      if (!sessionStorage.getItem('animPlayed') && window.location.pathname == '/'){
        $('#animation-container').css('display', 'block');
        $('body').removeClass('hidden');
        App.startAnim();
      }
      else {
        $('body').removeClass('hidden');
      }
      if ($('input[name="token"]').length){
        $('input[name="token"]').val($('meta[name="refresh"').attr('content'));
      }
      App.formTracking();
    },
    scroll: function (element, offset) {
      offset = offset === undefined ? App.config.scrollOffset : offset;

      $('html, body').animate({
        scrollTop: $(element).offset().top - offset
      }, 'slow');
    },

    menuBurger: function () {
      $burger = $('.menu-el > .icon-list-marker');
      $menu = $('.main-menu');
      $burger.on('click', function () {
        $menu.toggleClass('m--is-open');
      });
      $('.main-menu__wrapper a').on('click', function (){
        $menu.removeClass('m--is-open');
      });
    },

    startAnim: function() {
      var animContainer = document.getElementById('animation-container');
      sessionStorage.setItem('animPlayed', true);
      $('body').addClass('overlay');
      var animation = lottie.loadAnimation({
        container: animContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: '/assets/anim/logo.json'
      });
      var logoOffset = $('.header__top').offset().left;
      animation.addEventListener('complete', function(){
        $('#animation-container').css('left', logoOffset+'px');
        $('body').removeClass('overlay');
        animContainer.classList.add('after-anim');
      });
    },

    formTracking: function() {
      if (typeof(_paq) != undefined){
        if ($('.contact-form').length){
          if ($('.alert.success > p').text()) {
            _paq.push(['trackEvent', 'formulaire', 'formulaire-contact-generique', 'contact-generique-OK']);
          }
        }
        if ($('.job-offer-form').length){
          var location = window.location.href;
          if ($('.alert.success > p').text()) {
            _paq.push(['trackEvent', 'formulaire', 'formulaire-RH-reponse-offre-emploi', location]);
          }
        }
      }
    }
  };
  // ---------- APP END

  // Init app
  $(document).ready(App.init);
  window.App = App;

})(jQuery);
